import React from 'react'
import { Link } from 'gatsby'
import PageNav from '../../components/pageComponents/common/pageNav'
import Wrapper from '../../components/wrapper'
import YourJobsComponent from '../../components/pageComponents/memberDashboard/your-jobs'

const MemebrDashboardYourJobs = ({ location }) => {
  const Children = props => {
    return (
      <>
        <PageNav
          navFirstItem="Dashboard"
          navFirstItemLink="/member-dashboard/"
          navItem="Your Jobs"
        />
        <YourJobsComponent {...props} />
      </>
    )
  }
  return (
    <Wrapper privateRoute location={location} title="Dashboard | MedReps.com">
      <Children />
    </Wrapper>
  )
}
export default MemebrDashboardYourJobs
